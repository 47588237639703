<template>
    <div>
        <h3>{{ exerciseName }}</h3>
        <apexchart type="line" :options="chartOptions" :series="chartSeries"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        exerciseName: {
            type: String,
            required: true,
        },
        exerciseHistory: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            chartOptions: {
                 theme: {
                    mode: 'dark',
                },
                chart: {
                    height: "100px",  // This is the height of the chart in pixels.
                    type: 'line',
                    
                },
                xaxis: {
                    type: "datetime",
                    labels: {
                        format: "dd/MM/yyyy",
                    },
                },
                yaxis: {
                    forceNiceScale: true,
                    decimalsInFloat: 2,
                    title: {
                        text: "Peso (kg)",
                    },
                },
            },
            chartSeries: [
                {
                    name: "Peso",
                    data: this.exerciseHistory.map((record) => ({
                        x: record.date,
                        y: record.weight,
                    })),
                },
            ],
        };
    },
};
</script>
